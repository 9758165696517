import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { ContentSection } from "@ryerson/frontend.layout";
import { Media } from "@ryerson/frontend.layout";
import { Breadcrumb } from "@ryerson/frontend.navigation";
import { Typography } from "@ryerson/frontend.typography";

export type TrainingAndDevelopmentHeroContent = {
	title: string;
	description: string;
	quote: string;
	quoteAuthor: string;
	quoteAuthorTitle: string;
	quoteAuthorImage: string;
};

export type TrainingAndDevelopmentHeroProps = {
	content: TrainingAndDevelopmentHeroContent;
};

const BreadcrumbContainer = styled.div`
	margin-top: 32px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				margin-top: 8px;
			}
		`;
	}}
`;

const Left = styled.div`
	width: 38%;
	margin-right: 14%;
	display: inline-block;
	vertical-align: top;
`;

const Right = styled.div`
	position: relative;
	width: 48%;
	display: inline-block;
	vertical-align: top;
`;

const Divider = styled.div`
	width: 78.5%;
	margin-top: 70px;
	margin-bottom: 60px;
	opacity: 0.1;
	display: block;
	height: 1px;
	box-sizing: border-box;
`;

const Portrait = styled.div`
	display: block;
	width: 160px;
	height: 160px;
	position: absolute;
	top: -110px;
	right: 0;
	border-radius: 160px;
	background-size: cover;
	background-position: center;
	z-index: 0;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media (max-width: ${theme.breakpoints.lg}) {
				width: 60px;
				height: 60px;
				display: inline-block;
				position: static;
				vertical-align: middle;
				top: unset;
				right: unset;
				border-radius: 60px;
				margin-right: 15px;
			}
		`;
	}}
`;

const Author = styled.div`
	display: inline-block;
	width: calc(100% - 75px);
	vertical-align: middle;
	height: auto;
`;

const Spacer = styled.div`
	width: 100%;
	display: block;
	height: 90px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media (max-width: ${theme.breakpoints.lg}) {
				height: 15px;
			}
		`;
	}}
`;

const TrainingAndDevelopmentHero: React.FC<TrainingAndDevelopmentHeroProps> = ({ content }) => {
	const { theme } = useTheme();
	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection type="tertiary">
					<BreadcrumbContainer theme={theme}>
						<Breadcrumb type="tertiary" size="xs" />
					</BreadcrumbContainer>
					<Typography
						variant="h1"
						type="tertiary"
						css={css`
							margin-top: 25px;
						`}
					>
						{content.title}
					</Typography>
					<Divider
						css={css`
							border-bottom: 1px solid ${theme.colors.primary.white};
						`}
					/>
					<Left>
						<Typography type="tertiary" size="lg">
							{content.description}
						</Typography>
					</Left>
					<Right>
						<Typography
							css={css`
								margin-top: 0;
								z-index: 1;
								letter-spacing: -2.5px;
								position: relative;
								white-space: pre-line;
								margin-bottom: 17px;
							`}
							type="tertiary"
							variant="h2"
						>
							<span
								css={css`
									position: absolute;
									left: -20px;
									color: ${theme.colors.tertiary.text};
									top: 0;
								`}
							>
								“
							</span>
							{content.quote}
							<span
								css={css`
									margin-left: 6px;
									color: ${theme.colors.tertiary.text};
								`}
							>
								”
							</span>
						</Typography>
						<Typography color={theme.colors.primary.white} variant="div" size="sm">
							{content.quoteAuthor}
						</Typography>
						<Typography type="tertiary" variant="div" size="sm">
							{content.quoteAuthorTitle}
						</Typography>
						<Portrait
							theme={theme}
							css={css`
								background-image: url(${content.quoteAuthorImage});
							`}
						/>
					</Right>
					<Spacer theme={theme} />
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection type="tertiary">
					<BreadcrumbContainer theme={theme}>
						<Breadcrumb type="tertiary" size="xs" />
					</BreadcrumbContainer>
					<Typography
						variant="h1"
						type="tertiary"
						css={css`
							margin-top: 14px;
							margin-bottom: 20px;
						`}
					>
						{content.title}
					</Typography>
					<Typography size="md" color={theme.colors.primary.white}>
						{content.description}
					</Typography>
					<Typography
						css={css`
							position: relative;
							font-size: 20px;
							margin-top: 14px;
							margin-bottom: 20px;
						`}
						variant="h3"
						type="tertiary"
					>
						<span
							css={css`
								position: absolute;
								left: -10px;
								color: ${theme.colors.tertiary.text};
								top: 0;
							`}
						>
							“
						</span>
						{content.quote}
						<span
							css={css`
								margin-left: 4px;
								color: ${theme.colors.tertiary.text};
							`}
						>
							”
						</span>
					</Typography>
					<Portrait
						theme={theme}
						css={css`
							background-image: url(${content.quoteAuthorImage});
						`}
					/>
					<Author>
						<Typography color={theme.colors.primary.white} variant="div" size="sm">
							{content.quoteAuthor}
						</Typography>
						<Typography type="tertiary" variant="div" size="sm">
							{content.quoteAuthorTitle}
						</Typography>
					</Author>
					<Spacer theme={theme} />
				</ContentSection>
			</Media>
		</>
	);
};

export default TrainingAndDevelopmentHero;
